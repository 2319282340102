import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import './Results.css';

const getMonthName = (monthNum) => {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months[monthNum - 1] || '';
};

const karakaOrder7 = ['AK', 'AmK', 'BK', 'MK', 'PiK', 'GK', 'DK'];
const karakaOrder8 = ['AK', 'AmK', 'BK', 'MK', 'PiK', 'PuK', 'GK', 'DK'];

const Results = () => {
	
	useEffect(() => {
	const loadAds = () => {
		try {
			(window.adsbygoogle = window.adsbygoogle || []).push({});
		} catch (e) {
			console.error('Adsbygoogle failed to load:', e);
		}
	};
	loadAds();
    }, []);

    const location = useLocation(); // hook
    const navigate = useNavigate(); // hook

    // Get data from router state
    const data = location.state?.data || {};

    const onBack = () => {
        navigate('/', { state: { formData: location.state?.formData } });
    };
    
    const planetOrder = [
        'ascendant',
        'sun',
        'moon',
        'mercury',
        'venus',
        'mars',
        'jupiter',
        'saturn',
        'rahu',
        'ketu'
    ];

    // Add this function before the ChartResults component
    const formatTo12Hour = (hour, minute, second) => {
        const h = parseInt(hour);
        const ampm = h >= 12 ? 'PM' : 'AM';
        const hour12 = h % 12 || 12; // Convert 0 to 12 for 12 AM
        
        // Don't pad hour with zero, but pad minutes and seconds
        const minuteStr = String(minute).padStart(2, '0');
        const secondStr = String(second || '00').padStart(2, '0');
        
        return `${hour12}:${minuteStr}:${secondStr} ${ampm}`;
    };
    
    // Add safety check for data that redirects to home if data is missing
    if (!data || Object.keys(data).length === 0) {
        navigate('/');
        return null;
    }
    
    return (
        <div className="birth-chart-results">
            <div className="main-container">
                {/* First Row: Birth Info | D1 Table | D1 Chart */}
                <div className="row first-row">
                    <div className="birth-info-column">
                        <div className="birth-info">
                            <div>{data.name || 'Anonymous'}</div>
                            <div>Date of Birth: {data.day && data.month && data.year ? 
                                `${data.day} ${getMonthName(data.month)} ${data.year} (${data.birth_day})` : '-'}</div>
                            <div>Location: {data.city && data.country ? `${data.city}, ${data.country}` : '-'}</div>
                            <div>{typeof data.latitude === 'number' && typeof data.longitude === 'number' ? 
                                `Lat = ${data.latitude}, Long = ${data.longitude}` : '-'}</div>
                            <div>UTC Time: {data.utc_birth_time || '-'}</div>    
                            <div>Local Time: {formatTo12Hour(data.hour, data.minute, data.second)} ({data.utc_offset} UTC)</div>
                            <div>Sunrise/Sunset Type: {data.sunrise_sunset_type === 'astronomical' ? 'Astronomical' : 'Hindu'}</div>
                            <div>Sunrise: {data.sunrise || 'N/A'}</div>
                            <div>Sunset: {data.sunset || 'N/A'}</div>
                            <div>Moon Nakshatra: {data.moon_position?.nakshatra || '-'}</div>
                            <div>Node Type: {data.node_type || 'True'}</div>
                            <div>Year Type: {data.dasha_year_type === 'tropical' ? 'Tropical (365.242)' : 
                                             data.dasha_year_type === 'sidereal' ? 'Sidereal (365.256)' : 
                                             'Savana (360.0)'}</div>
                            <div>Karaka Scheme: {data.karaka_scheme === 'seven' ? '7 Planet' : '8 Planet'}</div>
                            <div>Ayanamsa: {data.ayanamsa ? `${data.ayanamsa} (${data.ayanamsa_value})` : '-'}</div>
                        </div>
                        
                    </div>
                    
                    <div className="d1-table-container">
                        <h3>Rasi (D1) Chart Details</h3>
                        <div className="table-scroll-container">
                            <table className="results-table d1-table">
                                <thead>
                                    <tr>
                                        <th className="planet-column">Planet</th>
                                        <th className="rasi-column">Rasi</th>
                                        <th className="nakshatra-column">Nakshatra</th>
                                        <th className="pada-column">Pada</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {planetOrder.map((planet) => {
                                        const positionKey = planet === 'ascendant' ? 'ascendant_position' : `${planet}_position`;
                                        const position = data[positionKey] || {};
                                        
                                        const status = [];
                                        if (position.retrograde) status.push('(R)');
                                        if (position.combust) status.push('(c)');
                                        const statusStr = status.length ? ' ' + status.join(' ') : '';

                                        return (
                                            <tr key={planet}>
                                                <td className="planet-column">
                                                    {planet.charAt(0).toUpperCase() + planet.slice(1)}{statusStr}
                                                </td>
                                                <td className="rasi-column">
                                                    <div className="rasi-sign">{position.sign || '-'}</div>
                                                    <div className="rasi-degrees">{position.degree || '-'}</div>
                                                </td>
                                                <td className="nakshatra-column">{position.nakshatra || '-'}</td>
                                                <td className="pada-column">{position.pada || '-'}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    <div className="chart d1-chart">
                        <div dangerouslySetInnerHTML={{ __html: data.chart_svg_d1 }} />
                    </div>
                </div>
                
                {/* Second Row: D9 Chart | Dasha | Karakas */}
                <div className="row second-row">
                    <div className="chart d9-chart">
                        <h3>Navamsa (D9) Chart</h3>
                        <div dangerouslySetInnerHTML={{ __html: data.chart_svg_d9 }} />
                    </div>
                            
                    <div className="chart d10-chart">
                        <h3>Dasamsa (D10) Chart</h3>
                        <div dangerouslySetInnerHTML={{ __html: data.chart_svg_d10 }} />
                    </div>
                    
                    <div className="dasha-wrapper">
                        <DashaDisplay dashas={data.dasha_periods} />
                    </div>
                    
                    <div className="karakas-wrapper">
                        <KarakaDisplay data={data} />
                        <button onClick={onBack} className="back-button">
                            <ArrowLeft size={16} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};



const DashaDisplay = ({ dashas }) => {
    const [selectedMahadasha, setSelectedMahadasha] = useState(null);
    const activeMahadasha = dashas?.find(dasha => dasha.is_active);
    
    const handleMahadashaClick = (dasha) => {
      setSelectedMahadasha(dasha);
    };
  
    const handleBack = () => {
      setSelectedMahadasha(null);
    };
  
    return (
      <div className="dasha-container">
          <div className="dasha-header">
            <h3>Vimshottari Dasha</h3>
            {selectedMahadasha && (
              <button onClick={handleBack} className="dasha-back-button">
                <ArrowLeft size={16} />
              </button>
            )}
          </div>
                
          <div className="dasha-subtitle">
            {selectedMahadasha 
              ? `${selectedMahadasha.planet.charAt(0).toUpperCase() + selectedMahadasha.planet.slice(1)} sub-periods`
              : `Mahadashas ${activeMahadasha ? `(Current: ${activeMahadasha.planet.charAt(0).toUpperCase() + activeMahadasha.planet.slice(1)})` : '(Current: N/A)'}`
            }
          </div>

          <div className="dasha-list-container">
            <div className="dasha-list">
              {selectedMahadasha 
                ? selectedMahadasha.antardashas.map((antardasha, index) => (
                    <div 
                      key={index}
                      className={`dasha-item antardasha-item ${antardasha.is_active ? 'active' : ''}`}>
                      <span className="dasha-planet">{antardasha.planet.slice(0, 2)}</span>
                      <span className="dasha-years">{antardasha.years_from_birth}</span>
                      <span className="dasha-date-range">
                        {antardasha.start_date} to {antardasha.end_date}
                      </span>
                    </div>
                  ))
                : dashas?.map((dasha, index) => (
                    <div 
                      key={index}
                      className={`dasha-item ${dasha.is_active ? 'active' : ''}`}
                      onClick={() => handleMahadashaClick(dasha)}
                    >
                      <span className="dasha-planet">{dasha.planet.slice(0, 2)}</span>
                      <span className="dasha-years">{dasha.years_from_birth}</span>
                      <span className="dasha-date-range">
                        {dasha.start_date} to {dasha.end_date}
                      </span>
                    </div>
                  ))
              }
            </div>
          </div>
      </div>
    );
};


const KarakaDisplay = ({ data }) => {
    const schemeType = data.karaka_scheme;
    const orderToUse = schemeType === 'seven' ? karakaOrder7 : karakaOrder8;
    const karakaData = data.jaimini_karakas || {};
    
    return (
        <div className="karaka-table-wrapper">
            <h3>Karakas</h3>
            <div className="karaka-table-container">
                <table className="karaka-table">
                    <thead>
                        <tr>
                            <th>Karaka</th>
                            <th>Planet</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderToUse.map(karaka => (
                            <tr key={karaka}>
                                <td>{karaka}</td>
                                <td>{karakaData[karaka] ? 
                                    karakaData[karaka].charAt(0).toUpperCase() + 
                                    karakaData[karaka].slice(1) : '-'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};


export default Results;
