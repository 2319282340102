import React, { useEffect } from 'react';
import '../App.css';

function Mahadasha() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        loadAds();
    }, []);

    return (
        <main>
            <section>
                <h1>Understanding Mahadasha in Vedic Astrology</h1>

                <h2>Introduction to Dashas</h2>
                <p>In Vedic astrology, dashas are a crucial concept for understanding the timing of events in a person's life. Dasha is a general term for planetary cycles that influence a person's life. The Mahadasha is the longest and most significant planetary period in a person's life. It represents the overarching theme of life during that period, based on the ruling planet. These time periods represent when our karmas, as shown in our birth chart, will actually manifest. Unlike Western astrology, which often focuses on possibilities and personality traits, Vedic astrology views a horoscope as a coded, mystical representation of why we are here and what we are meant to experience.</p>

                <p>A horoscope in Vedic astrology is seen as a map of effect -- the results of our past actions that will manifest as circumstances, people we meet, places we go, and deeds we will be compelled to do. While we have some free will in how we react to situations, the power of the grahas (planets) should not be underestimated in shaping our destiny.</p>

                <p>The concept of dashas is deeply rooted in the Vedic understanding of time and karma. While the birth chart represents a snapshot of the cosmic energies at the moment of birth, the dasha system reveals how these energies unfold throughout our lives. Think of it as a cosmic clock that determines when certain karmic fruits will ripen. This system acknowledges that not all planetary influences manifest simultaneously but rather take turns in influencing our life journey.</p>

                <h2>Vimshottari Dasha System</h2>
                <p>There are different types of dashas and the most popular dasha used in Vedic Astrology is the Vimshottari Dasha system. This system is considered perfect for the current time in human existence. When calculating the Vimshottari Dasha, it is crucial to ensure that the calculations are based on the sidereal zodiac, not the tropical zodiac. Key points about the Vimshottari Dasha system include:</p>

                <ol className="enlarged-text">
                    <li>It is a graha (planet) based prediction system where planets take over one's life for certain time periods.</li>
                    <li>The starting point is determined by the ruling planet of the nakshatra (lunar mansion) where the natal moon is placed.</li>
                    <li>The total cycle of Vimshottari Dasha is 120 years, which is considered the maximum length of human experience in the current age according to Vedic scriptures.</li>
                </ol>

                <p>The name "Vimshottari" itself holds significance - "Vimsho" means twenty, and "uttari" means beyond. This refers to the system's complete cycle of 120 years, which extends twenty years beyond the classical hundred-year life span. The system's effectiveness is attributed to its connection with the moon's position at birth, as the moon represents our mind and emotions in Vedic astrology. This lunar connection makes the Vimshottari system particularly attuned to our psychological and emotional development throughout life.</p>

                <h2>Mahadasha Order and Duration</h2>
                <p>The order of mahadashas in the Vimshottari system is fixed and circular:</p>

                <ol className="enlarged-text">
                    <li>Sun (Surya) - 6 years</li>
                    <li>Moon (Chandra) - 10 years</li>
                    <li>Mars (Mangala) - 7 years</li>
                    <li>Rahu (North Node) - 18 years</li>
                    <li>Jupiter (Guru) - 16 years</li>
                    <li>Saturn (Shani) - 19 years</li>
                    <li>Mercury (Buddha) - 17 years</li>
                    <li>Ketu (South Node) - 7 years</li>
                    <li>Venus (Shukra) - 20 years</li>
                </ol>

                <p>The starting mahadasha for an individual depends on their moon's position at birth, and the dashas continue in this order throughout their life. Suppose your Moon is in Swati Nakshatra, ruled by Rahu. You will start life with Rahu Mahadasha. If the Moon is 50% through Swati Nakshatra, you will experience only 50% of Rahu's total 18-year period, i.e., 9 years of Rahu Mahadasha at birth. After this, the next Mahadasha will be Jupiter (16 years), followed by Saturn (19 years), and so on.</p>

                <h2>General Effects of Mahadashas</h2>
                
                <h3>Sun Mahadasha:</h3>
                <ul className="enlarged-text">
                    <li>Fast-paced changes and intense experiences</li>
                    <li>Increased energy and ambition</li>
                    <li>Focus on righteous action (dharma)</li>
                    <li>Potential for accomplishments and success</li>
                    <li>Interactions with authority figures and government</li>
                </ul>

                <h3>Moon Mahadasha:</h3>
                <ul className="enlarged-text">
                    <li>Emphasis on emotions and feelings</li>
                    <li>Desire for belonging and connection</li>
                    <li>Potential for marriage and starting a family</li>
                    <li>Possible changes in living situation</li>
                    <li>Heightened sensitivity and intuition</li>
                </ul>

                <h3>Mars Mahadasha:</h3>
                <ul className="enlarged-text">
                    <li>Period of action and initiative</li>
                    <li>Increased courage and assertiveness</li>
                    <li>Potential for conflicts or aggressive behavior</li>
                    <li>Risk of accidents or injuries</li>
                    <li>Accomplishments through determined effort</li>
                </ul>

                <h3>Rahu Mahadasha:</h3>
                <ul className="enlarged-text">
                    <li>Period of illusion and unconventional experiences</li>
                    <li>Potential for significant success, but with unexpected outcomes</li>
                    <li>Opportunities for travel or living in foreign lands</li>
                    <li>Possible engagement in independent work or business ventures</li>
                    <li>Risk of getting caught up in illusions or deceptions</li>
                </ul>

                <h3>Jupiter Mahadasha:</h3>
                <ul className="enlarged-text">
                    <li>Optimistic and expansive period</li>
                    <li>Opportunities for education and spiritual growth</li>
                    <li>Potential for having children or mentoring others</li>
                    <li>Increased wisdom and understanding</li>
                    <li>Risk of overindulgence or laziness</li>
                </ul>

                <h3>Saturn Mahadasha:</h3>
                <ul className="enlarged-text">
                    <li>Period of hard work and discipline</li>
                    <li>Focus on building solid foundations</li>
                    <li>Potential for delays and obstacles, but also long-lasting achievements</li>
                    <li>Increased maturity and sense of responsibility</li>
                    <li>May bring a somber or serious outlook on life</li>
                </ul>

                <h3>Mercury Mahadasha:</h3>
                <ul className="enlarged-text">
                    <li>Emphasis on communication and intellectual pursuits</li>
                    <li>Increased adaptability and versatility</li>
                    <li>Opportunities for travel and learning</li>
                    <li>Potential for success in business or trade</li>
                    <li>May bring nervous energy or restlessness</li>
                </ul>

                <h3>Ketu Mahadasha:</h3>
                <ul className="enlarged-text">
                    <li>Period of spiritual growth and detachment</li>
                    <li>Potential for sudden changes or disruptions</li>
                    <li>Increased interest in mystical or occult subjects</li>
                    <li>Risk of accidents or health issues</li>
                    <li>May bring challenges in focusing on material pursuits</li>
                </ul>

                <h3>Venus Mahadasha:</h3>
                <ul className="enlarged-text">
                    <li>Focus on relationships, love, and pleasure</li>
                    <li>Potential for marriage or romantic involvements</li>
                    <li>Increased interest in arts, beauty, and luxury</li>
                    <li>Opportunities for social connections and enjoyment</li>
                    <li>Risk of overindulgence or attachment to material comforts</li>
                </ul>

                <h2>Judging the Effects of Mahadashas</h2>
                <p>To accurately predict the effects of a mahadasha on an individual's life, Vedic astrologers consider several factors:</p>

                <h3>1. The Ascendant (Lagna)</h3>
                <p>The relationship between the mahadasha lord and the ascendant is crucial. Planets ruling houses 1, 4, 5, 9, and 10 from the ascendant generally bring positive results, while those ruling houses 2, 3, 6, 7, 8, 11, and 12 may bring challenges.</p>

                <h3>2. Placement of the Mahadasha Planet</h3>
                <p>The strength and placement of the mahadasha planet in the natal chart is important. Factors to consider include:</p>

                <ul className="enlarged-text">
                    <li>Exaltation or debilitation</li>
                    <li>Placement in own sign or friendly sign</li>
                    <li>Placement in Kendra (angular) or Trikona (trine) houses</li>
                    <li>Aspects received from other planets</li>
                </ul>

                <h3>3. Aspects of the Mahadasha Planet</h3>
                <p>The aspects formed by the mahadasha planet to other houses and planets in the chart can significantly influence the results of the dasha period.</p>

                <p>The strength of a mahadasha period is significantly influenced by the natal promise shown in the birth chart. A well-placed planet in the birth chart will generally give better results during its mahadasha, even when facing challenging transits. Conversely, a poorly placed planet may struggle to deliver positive results even during favorable transits. This is why Vedic astrologers emphasize that dasha predictions must always be made in conjunction with the natal chart analysis, not in isolation.</p>

                <h2>Antardasha (Bhukti) Periods</h2>
                <p>Within each mahadasha, there are sub-periods called antardashas or bhuktis. These sub-periods follow the same order as the mahadashas and provide more detailed timing of events. Key points about antardashas include:</p>

                <ol className="enlarged-text">
                    <li>They represent other people or circumstances affecting the native during the main mahadasha.</li>
                    <li>The relationship between the antardasha lord and the mahadasha lord is important (friendship, enmity, or neutrality).</li>
                    <li>The placement of the antardasha lord from the mahadasha lord in the chart is crucial for predicting its effects.</li>
                </ol>

                <p>Favorable placements of antardasha lords are generally the 1st, 4th, 5th, 9th, and 10th houses from the mahadasha lord. Challenging placements include the 2nd, 3rd, 6th, 7th, 8th, 11th, and 12th houses from the mahadasha lord.</p>

                <p>The timing and duration of antardashas within each mahadasha are proportional to the total years of the main planet. For example, in Jupiter's 16-year mahadasha, Jupiter's own antardasha (which always comes first) would last approximately 2 years and 4 months, representing the same proportion as Jupiter's 16 years within the total 120-year cycle. In other words, the duration of each Antardasha is proportional to the planet's total Mahadasha length. This mathematical harmony reflects the Vedic principle of interconnectedness in cosmic timing.</p>

                <p>Understanding antardashas requires careful consideration of the relationship between planets. When the antardasha lord and mahadasha lord are natural friends and well-placed in the birth chart, they often produce favorable results during their combined period. However, when these planets are natural enemies or poorly placed, their periods might bring challenges that require careful navigation. Additionally, if either planet is involved in a powerful yoga (planetary combination) in the birth chart, that yoga's effects become particularly pronounced during their combined period.</p>

                <p>The quality of an antardasha period can also be influenced by the houses ruled by both the mahadasha and antardasha lords. For instance, if both planets rule auspicious houses like the 9th (dharma), 5th (creativity), or 1st (self), their combined period often brings positive developments in areas of spirituality, creativity, or personal growth. Conversely, if they rule challenging houses like the 6th, 8th, or 12th, their period might require more awareness and careful decision-making.</p>

                <p>Another important consideration in analyzing antardashas is the transit position of both planets during their period. While the birth chart positions are primary, the transit positions of both the mahadasha and antardasha lords can trigger or modify the results indicated in the birth chart. This is particularly true when either planet transits through its own sign, exaltation sign, or forms significant aspects with key points in the natal chart.</p>
            </section>
        </main>
    );
}

export default Mahadasha;
