import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Fuse from 'fuse.js';
import citiesData from 'cities.json';
import date from 'date-and-time';
import './Form.css';

const months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
];

const ayanamsaOptions = [
    'Lahiri', 'Fagan/Bradley', 'De Luce', 'Raman', 'Usha/Shashi',
    'Krishnamurti', 'Djwhal Khul', 'Yukteshwar', 'J.N. Bhasin',
    'Babylonian/Kugler 1', 'Babylonian/Kugler 2', 'Babylonian/Kugler 3',
    'Babylonian/Huber', 'Babylonian/Eta Piscium', 'Babylonian/Aldebaran = 15 Tau',
    'Hipparchos', 'Sassanian', 'Galact. Center = 0 Sag', 'J2000', 'J1900',
    'B1950', 'Suryasiddhanta', 'Suryasiddhanta, mean Sun', 'Aryabhata',
    'Aryabhata, mean Sun', 'SS Revati', 'SS Citra', 'True Citra', 'True Revati',
    'True Pushya', 'Galactic Center (Gil Brand)', 'Galactic Equator (IAU1958)',
    'Galactic Equator', 'Galactic Equator mid-Mula', 'Skydram (Mardyks)',
    'True Mula (Chandra Hari)', 'Dhruva/Gal.Center/Mula (Wilhelm)',
    'Aryabhata 522', 'Babylonian/Britton', 'Vedic/Sheoran',
    'Cochrane (Gal.Center = 0 Cap)', 'Galactic Equator (Fiorenza)',
    'Vettius Valens', 'Lahiri 1940', 'Lahiri VP285',
    'Krishnamurti-Senthilathiban', 'Lahiri ICRC', 'Tropical/Western'
];

const dashaYearOptions = [
    { value: 'tropical', label: 'Tropical: 365.242' },
    { value: 'sidereal', label: 'Sidereal: 365.256' },
    { value: 'savana', label: 'Savana: 360.0' }
];

const karakaSchemeOptions = [
    { value: 'seven', label: '7 Planet' },
    { value: 'eight', label: '8 Planet' }
];

const sunriseSunsetOptions = [
    { value: 'astronomical', label: 'Astronomical' },
    { value: 'hindu', label: 'Hindu' }
];

const MIN_YEAR = 21;    // 21 CE
const MAX_YEAR = 3000;  // 3000 CE

// Default form state
const defaultFormState = {
    name: '',
    year: '',
    month: '',
    day: '',
    hour: '',
    minute: '',
    second: '',
    latitude: '',
    longitude: '',
    city: '',
    country: '',
    ayanamsa: 'Lahiri',
    node_type: 'Mean',
    karaka_scheme: 'seven',
    dasha_year_type: 'tropical',
    sunrise_sunset_type: 'astronomical'
};

const fuseOptions = {
    keys: ['name'],
    threshold: 0.3,
    minMatchCharLength: 2
};
const fuseInstance = new Fuse(citiesData, fuseOptions);




function Form() {
    const navigate = useNavigate();
    const location = useLocation();
    const [yearError, setYearError] = useState('');
    const [dateError, setDateError] = useState('');
    
    // Use location state if available, otherwise use defaults
    const initialFormData = location.state?.formData || defaultFormState;
    const [formData, setFormData] = useState(initialFormData);

    // Initialize citySearch with the city and country if they exist
    const initialCitySearch = formData.city && formData.country 
        ? `${formData.city}, ${formData.country}` 
        : '';
    const [citySearch, setCitySearch] = useState(initialCitySearch);
    
    const [showCityDropdown, setShowCityDropdown] = useState(false);
    const [filteredCities, setFilteredCities] = useState([]);

    const days = Array.from({ length: 31 }, (_, i) => i + 1);
    const hours = Array.from({ length: 24 }, (_, i) => i);
    const minutes = Array.from({ length: 60 }, (_, i) => i);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.city-search')) {
                setShowCityDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const formatTime = (hour) => {
        const ampm = hour < 12 ? 'AM' : 'PM';
        const hour12 = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
        return `${hour} [${hour12} ${ampm}]`;
    };

    const handleCitySearch = (value) => {
        setCitySearch(value);
        
        // Clear only city and country from formData when user modifies the search
        if (formData.city || formData.country) {
            setFormData({
                ...formData,
                city: '',
                country: ''
            });
        }
        
        if (value.length > 2) {
            const searchResults = fuseInstance.search(value)
                .slice(0, 75)
                .map(result => result.item);
    
            setFilteredCities(searchResults);
            setShowCityDropdown(true);
        } else {
            setShowCityDropdown(false);
        }
    };
    
    const handleCitySelect = (city) => {
        setCitySearch(`${city.name}, ${city.country}`);
        setFormData({
            ...formData,
            latitude: city.lat.toString(),
            longitude: city.lng.toString(),
            city: city.name,
            country: city.country
        });
        setShowCityDropdown(false);
    };

    const handleYearChange = (e) => {
        setFormData({ ...formData, year: e.target.value });
        // Remove all validation code from here
        // The validation will happen only in handleSubmit, which already has year validation
    };
    
    const validateDate = () => {
        const { year, month, day } = formData;
        
        // Clear previous errors
        setDateError('');
        
        // Check if all date fields are filled
        if (!year || !month || !day) {
            return false;
        }

        // Create a date object using the date-and-time package
        const dateStr = `${year.toString().padStart(4, '0')}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        const isValidDate = date.isValid(dateStr, 'YYYY-MM-DD');

        if (!isValidDate) {
            setDateError('Invalid date. Please check the day, month, and year combination.');
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Clear all previous errors
        setYearError('');
        setDateError('');
    
        // Check year validity
        const yearValue = parseInt(formData.year);
        if (yearValue < MIN_YEAR || yearValue > MAX_YEAR) {
            setYearError(`Year must be between ${Math.abs(MIN_YEAR)} BCE and ${MAX_YEAR} CE`);
            return;
        }
    
        // Validate the date
        if (!validateDate()) {
            return;
        }
    
        try {
            const response = await fetch('https://api.vedicastro101.com/calculate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: formData.name,
                    year: parseInt(formData.year),
                    month: parseInt(formData.month),
                    day: parseInt(formData.day),
                    hour: parseInt(formData.hour),
                    minute: parseInt(formData.minute),
                    second: parseInt(formData.second),
                    latitude: parseFloat(formData.latitude),
                    longitude: parseFloat(formData.longitude),
                    ayanamsa: formData.ayanamsa,
                    node_type: formData.node_type,
                    karaka_scheme: formData.karaka_scheme,
                    dasha_year_type: formData.dasha_year_type,
                    sunrise_sunset_type: formData.sunrise_sunset_type,
                    city: formData.city,
                    country: formData.country
                })
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }
            
            const data = await response.json();
            navigate('/results', { 
                state: { 
                    data,
                    formData: formData // Pass form data to the results page
                } 
            });
        } catch (error) {
            console.error('Error:', error);
            alert(`Error generating chart: ${error.message}`);
        }
    };

    return (
        <div className="birth-chart-container">
            <div className="birth-chart-form">
                <h1>Vedic Birth Chart (Kundli) Calculator</h1>
                <form onSubmit={handleSubmit} className="form-content">
                    <div className="form-group form-group-single">
                        <label>Name <span className="optional-label">(Optional)</span></label>
                        <div className="form-row">
                        <input
                            type="text"
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value.slice(0, 41) })}
                            maxLength={41}
                            placeholder="Enter name"
                        />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Birth Date</label>
                        <div className="form-row">
                            <div className="year-input-container">
                                <input
                                    type="number"
                                    value={formData.year}
                                    onChange={handleYearChange}
                                    placeholder="Year"
                                    required
                                    className={yearError ? 'error' : ''}
                                />
                                {yearError && <div className="error-message">{yearError}</div>}
                                {dateError && <div className="error-message date-error">{dateError}</div>}
                            </div>
                            <div>
                                <select
                                    value={formData.month}
                                    onChange={(e) => setFormData({ ...formData, month: e.target.value })}
                                    required
                                >
                                    <option value="" disabled>Month</option>
                                    {months.map((month) => (
                                        <option key={month.value} value={month.value}>
                                            {month.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <select
                                    value={formData.day}
                                    onChange={(e) => setFormData({ ...formData, day: e.target.value })}
                                    required
                                >
                                    <option value="" disabled>Day</option>
                                    {days.map((day) => (
                                        <option key={day} value={day}>{day}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Birth Time</label>
                        <div className="form-row">
                            <div>
                                <select
                                    value={formData.hour}
                                    onChange={(e) => setFormData({ ...formData, hour: e.target.value })}
                                    required
                                >
                                    <option value="" disabled>Hour</option>
                                    {hours.map((hour) => (
                                        <option key={hour} value={hour}>{formatTime(hour)}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <select
                                    value={formData.minute}
                                    onChange={(e) => setFormData({ ...formData, minute: e.target.value })}
                                    required
                                >
                                    <option value="" disabled>Minute</option>
                                    {minutes.map((minute) => (
                                        <option key={minute} value={minute}>{minute}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <select
                                    value={formData.second}
                                    onChange={(e) => setFormData({ ...formData, second: e.target.value })}
                                >
                                    <option value="" disabled>Second</option>
                                    {minutes.map((second) => (
                                        <option key={second} value={second}>{second}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="form-group form-group-single">
                        <label>City of Birth</label>
                        <div className="form-row">
                            <div className="city-search">
                                <input
                                    type="text"
                                    value={citySearch}
                                    onChange={(e) => handleCitySearch(e.target.value)}
                                    placeholder="Enter city name..."
                                    required
                                />
                                {showCityDropdown && (
                                    <div className="city-dropdown">
                                        {filteredCities.map((city, index) => (
                                            <div
                                                key={index}
                                                className="city-option"
                                                onClick={() => handleCitySelect(city)}
                                            >
                                                {city.name}, {city.country}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="form-row coordinates-row">
                            <div>
                                <label>Latitude</label>
                                <input
                                    type="number"
                                    step="any"
                                    value={formData.latitude}
                                    onChange={(e) => setFormData({ ...formData, latitude: e.target.value })}
                                    onBlur={(e) => {
                                        // Round to 5 decimal places when user finishes typing
                                        if (e.target.value) {
                                            const roundedValue = parseFloat(parseFloat(e.target.value).toFixed(5));
                                            setFormData({ ...formData, latitude: roundedValue });
                                        }
                                    }}
                                    placeholder="Latitude"
                                    required
                                />
                            </div>
                            <div>
                                <label>Longitude</label>
                                <input
                                    type="number"
                                    step="any"
                                    value={formData.longitude}
                                    onChange={(e) => setFormData({ ...formData, longitude: e.target.value })}
                                    onBlur={(e) => {
                                        // Round to 5 decimal places when user finishes typing
                                        if (e.target.value) {
                                            const roundedValue = parseFloat(parseFloat(e.target.value).toFixed(5));
                                            setFormData({ ...formData, longitude: roundedValue });
                                        }
                                    }}
                                    placeholder="Longitude"
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="form-row options-row">
                            <div>
                                <label>Ayanamsa <span className="optional-label">(Optional)</span></label>
                                <select
                                    value={formData.ayanamsa}
                                    onChange={(e) => setFormData({ ...formData, ayanamsa: e.target.value })}
                                >
                                    {ayanamsaOptions.map((option) => (
                                        <option key={option} value={option}>{option}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Node Type <span className="optional-label">(Optional)</span></label>
                                <select
                                    value={formData.node_type}
                                    onChange={(e) => setFormData({ ...formData, node_type: e.target.value })}
                                >
                                    <option value="Mean">Mean</option>
                                    <option value="True">True</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-row">
                            <div>
                                <label>Karaka Scheme <span className="optional-label">(Optional)</span></label>
                                <select
                                    value={formData.karaka_scheme}
                                    onChange={(e) => setFormData({ ...formData, karaka_scheme: e.target.value })}
                                >
                                    {karakaSchemeOptions.map((option) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Year Definition <span className="optional-label">(Optional)</span></label>
                                <select
                                    value={formData.dasha_year_type}
                                    onChange={(e) => setFormData({ ...formData, dasha_year_type: e.target.value })}
                                >
                                    {dashaYearOptions.map((option) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Sunrise/Sunset <span className="optional-label">(Optional)</span></label>
                                <select
                                    value={formData.sunrise_sunset_type}
                                    onChange={(e) => setFormData({ ...formData, sunrise_sunset_type: e.target.value })}
                                >
                                    {sunriseSunsetOptions.map((option) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <button type="submit">Generate</button>
                </form>
            </div>
        </div>
    );
}

export default Form;
