import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="header-container">
      <div className="header-content">
        <Link to="/" className="header-link">
          <h1 className="site-title">VedicAstro101</h1>
        </Link>
        <p className="site-subtitle">Vedic Astrology Insights</p>
      </div>
    </header>
  );
};

export default Header;
