import React, { useEffect } from 'react';
import '../App.css';

function Privacy() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        loadAds();
    }, []);

    return (
        <main>
            <section>
                <h1>Privacy Policy</h1>

                <p>VedicAstro101.com is committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, and share your personal information when you visit our website.</p>

                <p>To put it simply, we do not collect any personal information at all. This website exists for educational purposes only. We do not sell anything or offer any kind of paid service. No user information is stored. However, should you choose to contact us voluntarily, you must provide your email along with the reason(s) for your contact.</p>

                <h2>Cookies</h2>

                <p>Cookies and similar tracking technologies are used to track user activities on websites and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>

                <h2>Contact Us</h2>

                <p>If you have any questions about privacy, please contact us using the form available on the About/Contact page.</p>
                
            </section>
        </main>
    );
}

export default Privacy;
