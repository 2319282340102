import React, { useState, useEffect } from 'react';
import '../App.css';
import Form from './Form';

function Home() {
    const [adBlockDetected, setAdBlockDetected] = useState(false);
    const [detectionComplete, setDetectionComplete] = useState(false);
    
    // Function to detect ad blockers - more comprehensive
    const detectAdBlock = () => {
        // Create multiple bait elements that ad blockers typically target
        const baitClasses = [
            'ad-banner',
            'adsbox',
            'ad-placement',
            'ad-placeholder',
            'ad-sidebar'
        ];
        
        const baits = [];
        
        // Create multiple bait elements with different classes
        baitClasses.forEach((className, index) => {
            const bait = document.createElement('div');
            bait.className = className;
            bait.id = `ad-bait-${index}`;
            bait.innerHTML = '&nbsp;';
            bait.style.position = 'absolute';
            bait.style.opacity = '0';
            bait.style.height = '1px';
            document.body.appendChild(bait);
            baits.push(bait);
        });
        
        // Wait longer for Safari/AdGuard to process the elements
        setTimeout(() => {
            // Check if ANY of the bait elements were blocked
            const blocked = baits.some(bait => {
                const isBlocked = bait.offsetHeight === 0 || 
                               bait.clientHeight === 0 || 
                               window.getComputedStyle(bait).display === 'none' ||
                               window.getComputedStyle(bait).height === '0px';
                
                return isBlocked;
            });
            
            // If blocked, set state and never change it back automatically
            if (blocked) {
                setAdBlockDetected(true);
            }
            
            // Mark detection as complete
            setDetectionComplete(true);
            
            // Clean up the bait elements
            baits.forEach(bait => {
                document.body.removeChild(bait);
            });
        }, 1000); // Longer timeout for more reliable detection
    };
    
    // Secondary detection method: check if AdSense was loaded
    const checkAdSenseLoaded = () => {
        // Only relevant in production, not on localhost
        if (window.location.hostname !== 'localhost') {
            // Check if Google AdSense was properly initialized
            const adSenseLoaded = 
                typeof window.adsbygoogle !== 'undefined' && 
                window.adsbygoogle.loaded === true;
            
            if (!adSenseLoaded) {
                setAdBlockDetected(true);
            }
            
            // Mark detection as complete
            setDetectionComplete(true);
        } else {
            // On localhost, just mark detection as complete
            setDetectionComplete(true);
        }
    };
    
    useEffect(() => {
        // Load ads
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        
        // Initial checks
        loadAds();
        detectAdBlock();
        
        // Use a longer timeout for the AdSense check
        const adSenseTimerId = setTimeout(checkAdSenseLoaded, 2000);
        
        return () => {
            clearTimeout(adSenseTimerId);
        };
    }, []);
    
    // Function to recheck if ad blocker is disabled
    const recheckAdBlocker = () => {
        window.location.reload();
    };
    
    // Show loading state until detection is complete
    if (!detectionComplete) {
        return (
            <main>
                <section id="home">
                    <div className="loading-message">
                        <h2>Loading...</h2>
                    </div>
                </section>
            </main>
        );
    }
    
    return (
        <main className="home-main">
            <section id="home">
                {adBlockDetected ? (
                    <div className="adblock-message">
                        <div style={{
                            backgroundColor: '#2c3e50',
                            padding: '20px',
                            borderRadius: '8px',
                            textAlign: 'center',
                            maxWidth: '600px',
                            margin: '0 auto',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
                        }}>
                            <h2 style={{color: '#ff6f61', marginTop: '0'}}>Ad Blocker Detected</h2>
                            <p style={{fontSize: '1.1rem', lineHeight: '1.6'}}>
                                We've detected that you're using an ad blocker. We understand that ads can be annoying and intrusive. 
                                However, this website solely relies on ads and offers no other paid services. Without ads, it cannot exist.
                            </p>
                            <p style={{fontSize: '1.1rem', lineHeight: '1.6'}}>
                                Please consider whitelisting this website. We apologize for any inconvenience caused.
                            </p>
                            <button 
                                onClick={recheckAdBlocker}
                                style={{
                                    backgroundColor: '#ff6f61',
                                    color: 'white',
                                    border: 'none',
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    fontSize: '1rem',
                                    cursor: 'pointer',
                                    marginTop: '10px'
                                }}
                            >
                                I've Disabled My Ad Blocker - Check Again
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="calculator-container">
                        <Form />
                    </div>
                )}
            </section>
        </main>
    );
}

export default Home;
