import React, { useEffect } from 'react';
import '../App.css';

function About() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        loadAds();
    }, []);

    return (
        <main>
            <section>
                <h1>About Us</h1>

                <p>Thank you for checking out this Vedic Astrology website. This website was made to help people explore the various aspects of vedic astrology in a simple manner. It aims to save people time and give them the most important information they might be curious about in one place. It is not meant to be comprehensive. However, it does discuss the most common topics related to vedic birth charts and vedic astrology in general. For many people, just knowing the basics is enough. This website does strive to answer some of the most common questions people might have about their birth charts. For more curious folks, it serves as a starting point to jump deeper into the topic if they wish too.</p>

                <p>The vast majority of people are only aware of the Western Astrology Sun sign made famous by popular culture. The truth is that many people do not even understand the intricacies of Western Astrology. Imagine how much more unfamiliar Vedic Astrology might be then? Once discovered, the journey to learning Vedic Astrology can be quite challenging, and this is where we want to help out. Our mission is to give you a head start on your path to discovering vedic astrology, especially your own chart results.</p>

                <h2>Our Mission</h2>

                <p>Upon gaining exposure to Vedic Astrology, there are a few common themes and topics that most people immediately wonder about in their birth charts. These common themes often take a lot of time to research and understand. Most people are too busy to invest this much time in exploring such a deep topic. Sometimes, the information available may be incomplete or purposefully hidden in order to sell consultations, reports, or other services. This is why we decided to put some of the most basic topics on this subject all under one roof.</p>

                <p>Our website is designed to save you time and money by providing the essential information you're likely to be curious about. We strive to make Vedic Astrology more accessible and easier to understand for everyone. We believe in sharing knowledge openly and freely, ensuring that you have access to the most relevant and valuable insights into Vedic Astrology. As a result, this website offers absolutely no paid services and is wholly supported by ads.</p>

                <p>Our website is continually growing, and we are committed to improving existing topics and adding more interesting content over time. If there is a specific topic you want to see discussed, or if you have any suggestions, comments, or complaints, please feel free to reach out using the contact form below. Your feedback is invaluable in helping us improve and expand our content. We understand that there is contention towards various approaches and topics pertaining to vedic astrology. You may not agree with some of the information provided here. However, we will try to accommodate as many views as possible.</p>

                <p>Once again, thank you for visiting our website. We hope you find the information here helpful and enlightening on your journey to understanding Vedic Astrology.</p>

                <h2>Contact Us</h2>

                <p>If you have any questions, suggestions, or feedback, please use the form below to get in touch with us.</p>

                <iframe 
                    src="https://docs.google.com/forms/d/e/1FAIpQLSeAKRgIxi2xhf_LvJKv1zkHNykgRXNS_KVCVT-Y51WDcRgdsQ/viewform?embedded=true" 
                    width="100%" 
                    height="850" 
                    frameBorder="0" 
                    marginHeight="0" 
                    marginWidth="0"
                    title="Feedback Form">
                    Loading…
                </iframe>
                
            </section>
        </main>
    );
}

export default About;
